<template>
  <div class="c14 c42">
    <div style="margin: 5px 20px 5px 20px">
      <p class="c6">
        <span class="c1"
          >POL&Iacute;TICA DE PRIVACIDAD Y PROTECCI&Oacute;N DE DATOS
        </span>
      </p>
      <p class="c14 c51"><span class="c1">Fecha: 01.02.2022</span></p>
      <p class="c14 c26">
        <span class="c2"
          >Te explicamos de manera simple y detallada los usos que le damos a tu
          informaci&oacute;n, c&oacute;mo y por qu&eacute; procesamos tus datos
          y c&oacute;mo los resguardamos para garantizar que sean confidenciales
          y est&eacute;n seguros.</span
        >
      </p>
      <p class="c17 c46"><span class="c2"></span></p>
      <p class="c11">
        <span class="c31"
          >Formas en las que recogemos tu informaci&oacute;n:</span
        >
      </p>
      <ul class="c29 lst-kix_list_3-0 start">
        <li class="c11 c15 li-bullet-0">
          <span class="c7"
            >Cuando te registras o das informaci&oacute;n utilizando nuestros
            canales </span
          ><span class="c7">App o p&aacute;gina o sitio &nbsp;</span
          ><span class="c7">Web</span><span class="c2">&nbsp;PRESTER.</span>
        </li>
        <li class="c15 c14 c48 li-bullet-0">
          <span class="c2"
            >De fuentes confiables (proveedores de servicios, de bur&oacute;s de
            cr&eacute;dito y organismos p&uacute;blicos).</span
          >
        </li>
      </ul>
      <p class="c26 c14">
        <span class="c1">C&oacute;mo protegemos tus datos</span>
      </p>
      <p class="c11">
        <span class="c7"
          >Protegemos tu informaci&oacute;n personal con los m&aacute;s altos
          est&aacute;ndares de seguridad.</span
        ><span class="c28">&nbsp;</span>
      </p>
      <p class="c11">
        <span class="c61">No comercializamos tus datos.</span
        ><span class="c2"
          >&nbsp;Haciendo un uso responsable de la informaci&oacute;n personal,
          no s&oacute;lo protegemos la privacidad de quienes nos confiaron sus
          datos, sino que les permitimos operar con seguridad y confianza con
          nuestros servicios.</span
        >
      </p>
      <p class="c11 c17"><span class="c2"></span></p>
      <p class="c11">
        <span class="c2"
          >PRESTER se especializa en procesar y analizar &nbsp;grandes
          vol&uacute;menes de informaci&oacute;n, utilizando data no tradicional
          para construir un Score crediticio de los usuarios, brindando una
          soluci&oacute;n para la inclusi&oacute;n financiera.</span
        >
      </p>
      <p class="c11 c17"><span class="c2"></span></p>
      <p class="c11">
        <span class="c2"
          >Mediante la utilizaci&oacute;n de la ciencia de datos, m&eacute;todos
          estad&iacute;sticos y la elaboraci&oacute;n de algoritmos basados en
          grafos, &nbsp;PRESTER construye un modelo de evaluaci&oacute;n de
          riesgo (scoring) que permite evaluar a m&aacute;s personas y
          &nbsp;ampliar el acceso al mercado financiero.</span
        >
      </p>
      <p class="c11 c17"><span class="c2"></span></p>
      <p class="c26 c14">
        <span class="c2"
          >Al registrarte con tus datos personales, acept&aacute;s esta
          transferencia de datos, almacenamiento o procesamiento. &nbsp;PRESTER
          se compromete a tomar todas las medidas necesarias para garantizar que
          sus datos sean tratados de forma segura y de acuerdo con los
          m&aacute;s altos est&aacute;ndares de seguridad.</span
        >
      </p>
      <p class="c21 c14">
        <span class="c2"
          >Para proteger la informaci&oacute;n de cualquier
          visualizaci&oacute;n, modificaci&oacute;n, divulgaci&oacute;n no
          autorizada, &nbsp;PRESTER toma los siguientes recaudos:</span
        >
      </p>
      <ul class="c29 lst-kix_list_5-0 start">
        <li class="c21 c15 c14 li-bullet-0">
          <span class="c2"
            >Encriptaci&oacute;n de los servicios mediante el protocolo
            SSL.</span
          >
        </li>
        <li class="c21 c15 c14 li-bullet-0">
          <span class="c2"
            >Revisi&oacute;n de la pol&iacute;tica en materia de
            recolecci&oacute;n, almacenamiento y tratamiento de datos,
            incluyendo las medidas de seguridad f&iacute;sicas, para impedir el
            acceso no autorizado a nuestros sistemas.</span
          >
        </li>
        <li class="c21 c15 c14 li-bullet-0">
          <span class="c2"
            >L&iacute;mite al acceso de los contratistas, los agentes y los
            empleados de &nbsp;PRESTER a la informaci&oacute;n suministrada por
            el usuario a ser procesada asegurando el cumplimiento de estrictas
            obligaciones de confidencialidad establecidas contractualmente junto
            con eventuales consecuencias derivadas del incumplimiento de las
            mismas.</span
          >
        </li>
      </ul>
      <p class="c14 c17 c21"><span class="c2"></span></p>
      <p class="c26 c14">
        <span class="c56"
          >Tu privacidad es muy importante para nosotros y nos esforzamos para
          protegerla.</span
        >
      </p>
      <p class="c11">
        <span class="c2"
          >Con el &nbsp;fin de poder brindarte el mejor servicio, tratamos,
          recolectamos y almacenamos informaci&oacute;n sobre las personas que,
          como vos, son usuarios y visitantes de los sitios web y/o aplicaciones
          m&oacute;viles, conforme se detalla aqu&iacute; (las
          &quot;Plataformas&quot; y los &quot;Servicios&quot;, respectivamente).
        </span>
      </p>
      <p class="c11 c17"><span class="c2"></span></p>
      <p class="c11">
        <span class="c2"
          >Esta Declaraci&oacute;n de Privacidad y Confidencialidad de la
          Informaci&oacute;n (la &quot;Declaraci&oacute;n de Privacidad&quot;)
          describe la informaci&oacute;n que recolectamos y trata sobre vos y lo
          que puede hacer con ella.</span
        >
      </p>
      <p class="c11 c17"><span class="c28"></span></p>
      <p class="c11">
        <span class="c61"
          >Prestar tu consentimiento voluntario, expreso e informado a esta
          Declaraci&oacute;n de Privacidad es un requisito esencial para poder
          contratar y/o tener cualquier tipo de relaci&oacute;n con la
          empresa.</span
        >
      </p>
      <p class="c8">
        <span class="c1"
          >Informaci&oacute;n que nos proporcionas directamente al registrarte o
          utilizar nuestros servicios</span
        >
      </p>
      <p class="c18 c14">
        <span class="c2">- Nombre, imagen personal (foto selfie) </span>
      </p>
      <p class="c18 c14">
        <span class="c2"
          >- N&uacute;mero de documento o identificaci&oacute;n v&aacute;lida
          (foto del documento de identidad)</span
        >
      </p>
      <p class="c18 c14">
        <span class="c2"
          >- Informaci&oacute;n de contacto (como n&uacute;mero de
          tel&eacute;fono, domicilio, direcci&oacute;n de correo
          electr&oacute;nico)</span
        >
      </p>
      <p class="c14 c18">
        <span class="c7 c19">- </span
        ><span class="c7 c19"
          >Informaci&oacute;n de ingresos, ocupaci&oacute;n, actividad </span
        ><span class="c7 c19">econ&oacute;mica</span>
      </p>
      <p class="c18 c14"><span class="c2">- Datos de cuenta bancaria</span></p>
      <p class="c8">
        <span class="c45"
          >Informaci&oacute;n que recopilamos de manera autom&aacute;tica en el
          proceso de re</span
        ><span class="c1 c19">gistro de la aplicaci&oacute;n PRESTER:</span>
      </p>
      <p class="c8">
        <span class="c16"
          >Con el objetivo de validar tu identidad y darte acceso a servicios
          financieros, analizaremos esta informaci&oacute;n de manera
          complementaria a la que utilizan los proveedores financieros
          tradicionales.</span
        >
      </p>
      <p class="c18 c14">
        <span class="c36">-</span
        ><span class="c7"
          >&nbsp;Informaci&oacute;n de los dispositivos desde los que accedes a
          la aplicaci&oacute;n de PRESTER y otros datos capturados
          autom&aacute;ticamente (como el tipo del sistema operativo, marca y
          modelo del dispositivo m&oacute;vil, &nbsp;direcci&oacute;n IP,
          configuraciones, datos de conexiones, aplicaciones descargadas).</span
        >
      </p>
      <p class="c18 c14">
        <span class="c2"
          >- Lista de contactos de los dispositivos m&oacute;viles para validar
          tu identidad, prevenir fraudes digitales y evaluar tu perfil
          crediticio te pediremos acceso a tu lista de contactos.</span
        >
      </p>
      <p class="c43 c14">
        <span class="c2 c19"
          >- Reconoces que PRESTER transmitir&aacute; los datos mencionados a
          trav&eacute;s de una API tomando todas las medidas de seguridad para
          garantizar la privacidad de tus datos.</span
        >
      </p>
      <p class="c18 c14">
        <span class="c7"
          >- Informaci&oacute;n sobre tu ubicaci&oacute;n
          (geolocalizaci&oacute;n), que es principalmente &nbsp;utilizada para
          confirmar que te encontr&aacute;s en el &aacute;rea de cobertura para
          ofrecerte servicios, entre otros.</span
        >
      </p>
      <p class="c8">
        <span class="c45"
          >Informaci&oacute;n que recopilamos de otras fuentes</span
        >
      </p>
      <p class="c18 c14">
        <span class="c2"
          >- Informaci&oacute;n recopilada para finalidades de prevenci&oacute;n
          de fraude y cumplimiento de reg&iacute;menes informativos (listas
          PEPs, OFAC, etc.).</span
        >
      </p>
      <p class="c18 c14">
        <span class="c2"
          >- Informaci&oacute;n crediticia, positiva y negativa, que obtenemos
          de bases o centrales de riesgo crediticio, empresas de
          telecomunicaciones y/o de fuentes de acceso p&uacute;blico, de
          conformidad con la legislaci&oacute;n aplicable.</span
        >
      </p>
      <p class="c18 c14">
        <span class="c7"
          >- Datos que se utilizan para la validaci&oacute;n de identidad,
          completar o corregir informaci&oacute;n, obtenidos de fuentes seguras
          y confiables, tales como organismos p&uacute;blicos, proveedores de
          servicios o aliados comerciales con los que trabajamos.</span
        >
      </p>
      <p class="c3">
        <span class="c7"
          >El Usuario puede registrarse en la APP o sitio web PRESTER con Google
          y Gmail sign in: </span
        ><span class="c7">https</span
        ><span class="c2"
          >://www.google.com.ar/intl/es/policies/privacy/key-terms/#toc-terms-account.
          Google podr&aacute; proporcionar a PRESTER informaci&oacute;n sobre el
          Usuario seg&uacute;n lo definido en la pol&iacute;tica de Privacidad
          de Google. PRESTER acepta y cumple con la Pol&iacute;tica de
          Privacidad definida por Google, a la que se puede acceder a
          trav&eacute;s del siguiente enlace:
          https://www.google.com/intl/es_ar/policies/privacy/
        </span>
      </p>
      <p class="c3">
        <span class="c2"
          >El Usuario puede registrarse en la App PRESTER con Facebook sign in:
          https://www.facebook.com/about/privacy/your-info-on-other. Facebook
          podr&aacute; proporcionar a PRESTER informaci&oacute;n sobre el
          usuario seg&uacute;n lo definido en la Pol&iacute;tica de Privacidad
          de Facebook.
        </span>
      </p>
      <p class="c3">
        <span class="c7">La APP o sitio web PRESTER cumple co</span
        ><span class="c7"
          >n la Pol&iacute;tica de Privacidad definida por Facebook, a la que se
          puede acceder a trav&eacute;s del siguiente enlace: </span
        ><span class="c7"
          ><a
            class="c20"
            href="https://www.google.com/url?q=https://www.facebook.com/about/privacy/&amp;sa=D&amp;source=editors&amp;ust=1650475798173287&amp;usg=AOvVaw2kEz4EJjtiy19pEtz3vH5Y"
            >https://www.facebook.com/about/privacy/</a
          ></span
        ><span class="c7">.</span>
      </p>
      <p class="c18 c14">
        <span class="c28"
          >En algunos casos, esta informaci&oacute;n podr&iacute;a ser
          considerada sensible de acuerdo a la legislaci&oacute;n aplicable. En
          estos casos, solicitamos tu consentimiento expreso para
          procesarlos.</span
        >
      </p>
      <p class="c18 c14">
        <span class="c1"
          >&nbsp;&iquest;Qu&eacute; hacemos con la informaci&oacute;n
          personal?</span
        >
      </p>
      <p class="c18 c14">
        <span class="c7"
          >La recolecci&oacute;n y tratamiento de tu informaci&oacute;n personal
          nos permite brindarte un excelente servicio para que puedas realizar
          operaciones de forma r&aacute;pida y segura y ofrecerte
          funcionalidades que se adaptan mejor a tus necesidades. Salvo en
          aquellos casos en los que la normativa aplicable lo
          proh&iacute;ba,</span
        ><span>&nbsp;</span
        ><span class="c2"
          >PRESTER podr&aacute; utilizar tu informaci&oacute;n personal para las
          siguientes finalidades:</span
        >
      </p>
      <ul class="c29 lst-kix_list_1-0 start">
        <li class="c40 c15 c14 li-bullet-0">
          <span class="c7"
            >Verificar tu identidad en cumplimiento de exigencias legales.</span
          >
        </li>
        <li class="c22 c15 c14 li-bullet-0">
          <span class="c7"
            >Validar, actualizar y corregir tu informaci&oacute;n.</span
          >
        </li>
        <li class="c22 c15 c14 li-bullet-0">
          <span class="c7"
            >Brindarte los servicios y/o beneficios que solicit&aacute;s o
            contrat&aacute;s con nosotros (por ejemplo un cr&eacute;dito
            personal).</span
          >
        </li>
        <li class="c22 c15 c14 li-bullet-0">
          <span class="c7"
            >Elaborar y mantener un registro de las operaciones que
            realic&eacute;s, as&iacute; como informarte acerca de las mismas y
            darle seguimiento correspondiente (por ejemplo el avance de pago de
            cuotas de tu pr&eacute;stamo)</span
          >
        </li>
        <li class="c15 c14 c22 li-bullet-0">
          <span class="c7"
            >Atender tus comentarios, quejas y sugerencias, as&iacute; como
            brindarte soporte.</span
          >
        </li>
        <li class="c22 c15 c14 li-bullet-0">
          <span class="c7"
            >Consultar y reportar datos positivos y negativos en las centrales
            de riesgos crediticios, realizar labores de verificaci&oacute;n de
            riesgo comercial y/o de cr&eacute;dito, analizar la viabilidad de
            celebrar o mantener una relaci&oacute;n comercial y elaborar
            perfiles con fines de an&aacute;lisis crediticio.</span
          >
        </li>
        <li class="c22 c15 c14 li-bullet-0">
          <span class="c7"
            >Elaborar perfiles con fines de an&aacute;lisis crediticio.</span
          >
        </li>
        <li class="c22 c15 c14 li-bullet-0">
          <span class="c7"
            >Ofrecerte y gestionar los productos crediticios que solicites o
            contrates con nosotros.</span
          >
        </li>
        <li class="c22 c15 c14 li-bullet-0">
          <span class="c7">Cobranza judicial y/o extrajudicial. </span>
        </li>
      </ul>
      <p class="c8 c33">
        <span class="c53"
          >Adem&aacute;s, en PRESTER dise&ntilde;amos nuevas formas de promover
          y ampliar el acceso a servicios financieros de calidad para vos.</span
        >
      </p>
      <ul class="c29 lst-kix_list_6-0 start">
        <li class="c8 c15 li-bullet-0">
          <span class="c7"
            >Elaborar perfiles mediante el an&aacute;lisis de diversas
            variables, como la conducta o las interacciones dentro de la
            plataforma, el an&aacute;lisis y predicci&oacute;n de la capacidad
            econ&oacute;mica, preferencias, intereses, historial de
            cr&eacute;ditos, comportamiento y ubicaci&oacute;n, entre otros,
            para mejorar nuestras iniciativas de inclusi&oacute;n
            financiera.</span
          >
        </li>
      </ul>
      <p class="c8">
        <span class="c45"
          >Cumplir con obligaciones legales y requerimientos de autoridades
          competentes.</span
        >
      </p>
      <ul class="c29 lst-kix_list_2-0 start">
        <li class="c40 c15 c14 li-bullet-0">
          <span class="c7"
            >Cumplimiento de normativa de Prevenci&oacute;n de Lavado de Dinero
            y Financiamiento del Terrorismo (acciones de validaci&oacute;n de
            identidad (KYC), verificaci&oacute;n de identidad contra de Personas
            Expuestas Pol&iacute;ticamente, verificaci&oacute;n de perfil e
            historial transaccional, en cumplimiento de la regulaci&oacute;n
            aplicable en materia de prevenci&oacute;n de lavado de dinero,
            verificaci&oacute;n contra listas OFAC y otras), seg&uacute;n sea
            aplicable en cada pa&iacute;s.</span
          >
        </li>
        <li class="c22 c15 c14 li-bullet-0">
          <span class="c7"
            >Cumplimiento de reg&iacute;menes informativos en general,
            seg&uacute;n sea aplicable en cada pa&iacute;s.</span
          >
        </li>
        <li class="c22 c15 c14 li-bullet-0">
          <span class="c7"
            >Cumplimiento de reg&iacute;menes fiscales de recaudaci&oacute;n,
            registraci&oacute;n, informaci&oacute;n, auditor&iacute;a y
            facturaci&oacute;n, a cualquier nivel de gobierno (por ejemplo:
            nivel federal, estadual y municipal).</span
          >
        </li>
        <li class="c15 c14 c59 li-bullet-0">
          <span class="c7"
            >Cumplimiento de requerimientos informativos de autoridades
            administrativas o judiciales competentes.</span
          >
        </li>
      </ul>
      <p class="c18 c14 c23">
        <span class="c1"
          >Detectar y prevenir fraudes, abusos y delitos relacionados para
          proteger la seguridad de nuestros usuarios y la sustentabilidad de las
          plataformas.</span
        >
      </p>
      <ul class="c29 lst-kix_list_4-0 start">
        <li class="c15 c14 c40 li-bullet-0">
          <span class="c7"
            >Hacer que las plataformas crezcan de una manera sustentable y
            segura mediante herramientas y acciones de prevenci&oacute;n del
            fraude y delitos relacionados.</span
          >
        </li>
        <li class="c59 c15 c14 li-bullet-0">
          <span class="c7"
            >Entrenar el modelo de algoritmo de detecci&oacute;n y
            prevenci&oacute;n automatizada del fraude.</span
          >
        </li>
      </ul>
      <h1 class="c23 c14 c55" id="h.7luxqr9rt9d4">
        <span class="c1">No compartimos la informaci&oacute;n personal</span>
      </h1>
      <p class="c18 c14">
        <span class="c7"
          >El resguardo de tu privacidad es muy importante para PRESTER.
          &nbsp;Por ello,</span
        ><span class="c28"
          >&nbsp;no vendemos ni comercializamos informaci&oacute;n que
          identifique a nuestros usuarios. Tampoco compartimos o transferimos de
          ning&uacute;n otro modo tu informaci&oacute;n personal a
          terceros.</span
        >
      </p>
      <h1 class="c55 c23 c14" id="h.1rair1egsoq7">
        <span class="c1"
          >&iquest;Por cu&aacute;nto tiempo vamos a almacenar la
          informaci&oacute;n personal?</span
        >
      </h1>
      <p class="c18 c14">
        <span class="c2"
          >Solo almacenaremos la informaci&oacute;n personal durante el tiempo
          necesario para cumplir con el prop&oacute;sito para el que se ha
          recopilado, para cumplir con requisitos reglamentarios o legales, o
          durante el periodo de prescripci&oacute;n legal de posibles
          responsabilidades legales o contractuales.</span
        >
      </p>
      <p class="c18 c14">
        <span class="c2"
          >Una vez concluido el lapso, los datos ser&aacute;n eliminados o
          anonimizados de manera tal que no pueda ser individualizada ninguna
          persona.</span
        >
      </p>
      <h1 class="c55 c14" id="h.sj9q8vieulwv">
        <span class="c1"
          >Decisiones automatizadas y elaboraci&oacute;n de perfiles</span
        >
      </h1>
      <p class="c18 c14">
        <span class="c7"
          >PRESTER dise&ntilde;a nuevos servicios para promover la
          inclusi&oacute;n financiera mediante la utilizaci&oacute;n de scoring
          alternativo y asistir a aquellas personas que no presentan o no
          calificar&iacute;an al ser analizadas de manera tradicional.</span
        >
      </p>
      <p class="c18 c14">
        <span class="c2 c19"
          >Para esto recurrimos a herramientas que nos ayudan a ser m&aacute;s
          eficientes mediante t&eacute;cnicas conocidas como &ldquo;inteligencia
          artificial&rdquo;, &ldquo;machine learning&rdquo; o &ldquo;Big
          Data&rdquo; que utilizamos para diversos fines, como prevenci&oacute;n
          del fraude, an&aacute;lisis y predicci&oacute;n de la capacidad
          econ&oacute;mica, mecanismos de ciberseguridad automatizados o
          validaci&oacute;n de identidad mediante reconocimiento de
          im&aacute;genes.</span
        >
      </p>
      <p class="c18 c14">
        <span class="c2"
          >Las decisiones automatizadas son aquellas que se toman en base al uso
          de algoritmos y programas inform&aacute;ticos, sin que intervenga
          ning&uacute;n ser humano en el proceso de la toma de
          decisi&oacute;n.</span
        >
      </p>
      <p class="c18 c14">
        <span class="c7"
          >Por su parte, la elaboraci&oacute;n de perfiles es la
          evaluaci&oacute;n de algunos aspectos personales, como comportamientos
          o ubicaci&oacute;n, que se realiza procesando de manera automatizada
          informaci&oacute;n personal con procedimientos estad&iacute;sticos.
        </span>
      </p>
      <h1 class="c23 c14 c47" id="h.97zv9dajlej1">
        <span class="c30">Cookies y Otras </span
        ><span class="c30">Tecnolog&iacute;as</span>
      </h1>
      <p class="c18 c14">
        <span class="c2"
          >Reconoc&eacute;s y acept&aacute;s expresamente que PRESTER
          podr&aacute; utilizar un sistema de seguimiento de conducta mediante
          la utilizaci&oacute;n de &quot;cookies&quot; y/u otras
          tecnolog&iacute;as similares de seguimiento.</span
        >
      </p>
      <p class="c18 c14">
        <span class="c2"
          >Tus datos personales obtenidos a trav&eacute;s de estas
          tecnolog&iacute;as no ser&aacute;n transferidos a terceros.</span
        >
      </p>
      <p class="c18 c14">
        <span class="c7"
          >Ten&eacute; en cuenta que la instalaci&oacute;n, permanencia y
          existencia de las cookies en tu computadora o dispositivo depende de
          tu exclusiva voluntad y pueden ser eliminadas cuando as&iacute; lo
          desees. Para saber c&oacute;mo quitar las Cookies del sistema es
          necesario revisar la secci&oacute;n Ayuda (Help) del navegador.</span
        >
      </p>
      <p class="c32 c14"><span class="c1">Tus derechos </span></p>
      <p class="c14 c32">
        <span class="c7"
          >Ten&eacute;s derecho a acceder, actualizar, corregir, eliminar o
          cancelar tu informaci&oacute;n personal y oponerte a que usemos tus
          datos. Pod&eacute;s comunicarte con nosotros a trav&eacute;s de </span
        ><span class="c7">consultas@prester.com.ar</span
        ><span class="c7"
          >&nbsp;o a trav&eacute;s de la l&iacute;nea gratuita de
          Atenci&oacute;n al cliente de Wh</span
        ><span class="c2">atsApp +549 11 6350 4807.</span>
      </p>
      <p class="c32 c14 c17"><span class="c2"></span></p>
      <p class="c18 c14">
        <span class="c50"
          >Seguridad. Almacenamiento de la informaci&oacute;n personal</span
        >
      </p>
      <p class="c43 c14">
        <span class="c2"
          >cumple con la normativa y ha adoptado medidas de seguridad siguiendo
          los est&aacute;ndares de la industria para proteger tu
          informaci&oacute;n personal.
        </span>
      </p>
      <p class="c43 c14">
        <span class="c2"
          >En la medida en que PRESTER haya cumplido con las normas y adoptado
          las medidas mencionadas en el apartado anterior, no se hace
          responsable por interceptaciones ilegales o violaciones de sus
          sistemas o bases de datos, ni por su utilizaci&oacute;n por parte de
          personas no autorizadas. PRESTER tampoco se hace responsable por la
          indebida utilizaci&oacute;n de la informaci&oacute;n obtenida por esos
          medios.</span
        >
      </p>
      <h1 class="c47 c23 c14" id="h.nc2np68ebksc">
        <span class="c5">Ley Aplicable y Jurisdicci&oacute;n</span>
      </h1>
      <p class="c14 c43">
        <span class="c2"
          >La Declaraci&oacute;n de Privacidad se regir&aacute; por las leyes
          aplicables conforme se detalla en los anexos de cada pa&iacute;s
          incluidos debajo. Ante cualquier controversia o divergencia
          relacionada con la interpretaci&oacute;n, validez, celebraci&oacute;n
          o cumplimiento de la presente Declaraci&oacute;n de Privacidad, se
          resolver&aacute; por los tribunales competentes indicados en dichos
          anexos de cada pa&iacute;s incluidos debajo.</span
        >
      </p>
      <p class="c18 c14"><span class="c19 c60">ARGENTINA</span></p>
      <p class="c18 c14">
        <span class="c5 c14"
          >&iquest;Qui&eacute;n es el responsable del tratamiento de la
          Informaci&oacute;n Personal?</span
        >
      </p>
      <p class="c43 c14">
        <span class="c7 c14"
          >En Argentina, los Servicios de PRESTER son prestados por </span
        ><span class="c7 c19">&nbsp;SIFT S.A</span
        ><span class="c7 c14">., </span
        ><span class="c35"
          >sociedad incorporada bajo las leyes de la Argentina, registrada ante
          la IGJ, bajo el N&uacute;mero 22758, del Libro 82 de S.A., </span
        ><span class="c7 c14"
          >CUIT 33-71544137-9, con domicilio en 25 de mayo 306, piso 2&deg;,
          Ciudad Aut&oacute;noma de Buenos Aires, C.P.: 1002. Rep&uacute;blica
          Argentina; Atte.: SIFT S.A. Dichos servicios son ofrecidos a
          trav&eacute;s de las Plataformas </span
        ><span class="c7 c14"
          ><a
            class="c20"
            href="https://www.google.com/url?q=http://www.findocreditos.com.ar&amp;sa=D&amp;source=editors&amp;ust=1650475798178553&amp;usg=AOvVaw3SaTIO4zTwhTCQczyoRsoQ"
            >www.prester.com.ar</a
          ></span
        ><span class="c7 c14"
          >&nbsp;y la App PRESTER publicada en GooglePlay Store</span
        ><span class="c0">.</span>
      </p>
      <p class="c18 c14">
        <span class="c5 c14"
          >&iquest;C&oacute;mo pod&eacute;s ejercer tus derechos para controlar
          tu Informaci&oacute;n Personal?</span
        >
      </p>
      <p class="c18 c14">
        <span class="c0"
          >Sin perjuicio de lo indicado en esta Declaraci&oacute;n de
          Privacidad, en cumplimiento de lo dispuesto por las disposiciones de
          la Ley N&deg; 25.326, el Decreto Reglamentario N&deg; 1558/2001 y las
          disposiciones y/o resoluciones vinculantes emitidas por la Agencia de
          Acceso a la Informaci&oacute;n P&uacute;blica se comunica que:
          &quot;el titular de los datos personales tiene la facultad de ejercer
          el derecho de acceso a los mismos en forma gratuita a intervalos no
          inferiores a seis meses, salvo que se acredite un inter&eacute;s
          leg&iacute;timo al efecto conforme lo establecido en el
          art&iacute;culo 14, inciso 3 de la Ley N&ordm; 25.326. LA AGENCIA DE
          ACCESO A LA INFORMACI&Oacute;N P&Uacute;BLICA, en su car&aacute;cter
          de &Oacute;rgano de Control de la Ley N&deg; 25.326, tiene la
          atribuci&oacute;n de atender las denuncias y reclamos que interpongan
          quienes resulten afectados en sus derechos por incumplimiento de las
          normas vigentes en materia de protecci&oacute;n de datos personales.
          El titular podr&aacute; en cualquier momento solicitar el retiro o
          bloqueo de su nombre de los bancos de datos a los que se refiere el
          presente art&iacute;culo. En toda comunicaci&oacute;n con fines de
          publicidad que se realice por correo, tel&eacute;fono, correo
          electr&oacute;nico, Internet u otro medio a distancia a conocer, se
          deber&aacute; indicar, en forma expresa y destacada, la posibilidad
          del titular del dato de solicitar el retiro o bloqueo, total o
          parcial, de su nombre de la base de datos. A pedido del interesado, se
          deber&aacute; informar el nombre del responsable o usuario del banco
          de datos que provey&oacute; la informaci&oacute;n.&quot;</span
        >
      </p>
      <p class="c18 c14">
        <span class="c0"
          >Sin perjuicio de lo dispuesto en la Declaraci&oacute;n de Privacidad,
          tambi&eacute;n podr&aacute;s realizar consultas y/o ejercer los
          derechos de acceso, rectificaci&oacute;n y supresi&oacute;n de tu
          Informaci&oacute;n Personal por correo postal a 25 de mayo 306, piso
          2&deg;, Ciudad Aut&oacute;noma de Buenos Aires, C.P.: 1002. Atte.:
          SIFT S.A.</span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalAllons",
  methods: {},
};
</script>


<style scoped>
/* 
@import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLcWpXo_CmM6erK5IinBZ-8PVus-cM8ZA-pXCeyO7rfhH96xlbbE5D7Gw2o7jubnkMA");
*/
ul.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_3-0 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_5-7 {
  list-style-type: none;
}
ul.lst-kix_list_5-8 {
  list-style-type: none;
}
.lst-kix_list_3-1 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_3-2 > li:before {
  content: "\0025a0 ";
}
ul.lst-kix_list_5-5 {
  list-style-type: none;
}
ul.lst-kix_list_5-6 {
  list-style-type: none;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
.lst-kix_list_3-5 > li:before {
  content: "\0025a0 ";
}
ul.lst-kix_list_5-0 {
  list-style-type: none;
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-1 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
  content: "\0025cb ";
}
ul.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_5-3 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_5-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_5-1 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ul.lst-kix_list_5-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
.lst-kix_list_3-8 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_3-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_3-7 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_5-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_4-8 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_5-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_4-7 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_5-2 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_5-1 > li:before {
  content: "\0025cb ";
}
ul.lst-kix_list_4-8 {
  list-style-type: none;
}
.lst-kix_list_5-7 > li:before {
  content: "\0025cb ";
}
ul.lst-kix_list_4-6 {
  list-style-type: none;
}
.lst-kix_list_5-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_5-8 > li:before {
  content: "\0025a0 ";
}
ul.lst-kix_list_4-7 {
  list-style-type: none;
}
ul.lst-kix_list_4-0 {
  list-style-type: none;
}
ul.lst-kix_list_4-1 {
  list-style-type: none;
}
.lst-kix_list_5-4 > li:before {
  content: "\0025cb ";
}
ul.lst-kix_list_4-4 {
  list-style-type: none;
}
.lst-kix_list_5-5 > li:before {
  content: "\0025a0 ";
}
ul.lst-kix_list_4-5 {
  list-style-type: none;
}
ul.lst-kix_list_4-2 {
  list-style-type: none;
}
ul.lst-kix_list_4-3 {
  list-style-type: none;
}
.lst-kix_list_6-1 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_6-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_6-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_6-4 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_6-2 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_6-8 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_6-5 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_6-7 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_6-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_2-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_2-7 > li:before {
  content: "\0025cb ";
}
ul.lst-kix_pxnval67nd97-0 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_2-5 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_2-8 > li:before {
  content: "\0025a0 ";
}
ul.lst-kix_list_3-7 {
  list-style-type: none;
}
ul.lst-kix_list_3-8 {
  list-style-type: none;
}
ul.lst-kix_pxnval67nd97-5 {
  list-style-type: none;
}
ul.lst-kix_list_3-1 {
  list-style-type: none;
}
ul.lst-kix_pxnval67nd97-6 {
  list-style-type: none;
}
ul.lst-kix_list_3-2 {
  list-style-type: none;
}
ul.lst-kix_pxnval67nd97-7 {
  list-style-type: none;
}
ul.lst-kix_pxnval67nd97-8 {
  list-style-type: none;
}
ul.lst-kix_list_3-0 {
  list-style-type: none;
}
ul.lst-kix_pxnval67nd97-1 {
  list-style-type: none;
}
ul.lst-kix_list_3-5 {
  list-style-type: none;
}
ul.lst-kix_pxnval67nd97-2 {
  list-style-type: none;
}
ul.lst-kix_list_3-6 {
  list-style-type: none;
}
ul.lst-kix_pxnval67nd97-3 {
  list-style-type: none;
}
ul.lst-kix_list_3-3 {
  list-style-type: none;
}
ul.lst-kix_pxnval67nd97-4 {
  list-style-type: none;
}
ul.lst-kix_list_3-4 {
  list-style-type: none;
}
.lst-kix_list_4-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_4-1 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_4-4 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_4-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_4-5 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_4-2 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_4-6 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_6-6 {
  list-style-type: none;
}
ul.lst-kix_list_6-7 {
  list-style-type: none;
}
ul.lst-kix_list_6-4 {
  list-style-type: none;
}
ul.lst-kix_list_2-8 {
  list-style-type: none;
}
ul.lst-kix_list_6-5 {
  list-style-type: none;
}
ul.lst-kix_list_6-8 {
  list-style-type: none;
}
.lst-kix_pxnval67nd97-0 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_2-3 {
  list-style-type: none;
}
ul.lst-kix_list_2-0 {
  list-style-type: none;
}
.lst-kix_pxnval67nd97-1 > li:before {
  content: "\0025cb ";
}
ul.lst-kix_list_2-1 {
  list-style-type: none;
}
ul.lst-kix_list_6-2 {
  list-style-type: none;
}
ul.lst-kix_list_2-6 {
  list-style-type: none;
}
ul.lst-kix_list_6-3 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_1-2 > li:before {
  content: "\0025a0 ";
}
ul.lst-kix_list_2-7 {
  list-style-type: none;
}
ul.lst-kix_list_6-0 {
  list-style-type: none;
}
ul.lst-kix_list_2-4 {
  list-style-type: none;
}
ul.lst-kix_list_6-1 {
  list-style-type: none;
}
.lst-kix_pxnval67nd97-2 > li:before {
  content: "\0025a0 ";
}
ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_1-4 > li:before {
  content: "\0025cb ";
}
.lst-kix_pxnval67nd97-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_pxnval67nd97-5 > li:before {
  content: "\0025a0 ";
}
.lst-kix_pxnval67nd97-4 > li:before {
  content: "\0025cb ";
}
.lst-kix_pxnval67nd97-8 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_1-7 > li:before {
  content: "\0025cb ";
}
.lst-kix_pxnval67nd97-7 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_1-5 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_1-6 > li:before {
  content: "\0025cf ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_pxnval67nd97-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_2-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_2-1 > li:before {
  content: "\0025cb ";
}
.lst-kix_list_1-8 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_2-2 > li:before {
  content: "\0025a0 ";
}
.lst-kix_list_2-3 > li:before {
  content: "\0025cf ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c0 {
  background-color: #ffffff;
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Roboto";
  font-style: normal;
}
.c10 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Calibri";
  font-style: normal;
}
.c3 {
  background-color: #ffffff;
  padding-top: 9pt;
  padding-bottom: 9pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c11 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  text-decoration: none !important;
}
.c13 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
}
.c8 {
  background-color: #ffffff;
  padding-top: 21pt;
  padding-bottom: 21pt;
  line-height: 1.3500000000000003;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c5 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 15pt;
  font-family: "Roboto";
  font-style: normal;
}
.c6 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 9pt;
  line-height: 1.25;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Roboto";
  font-style: normal;
}
.c1 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: "Roboto";
  font-style: normal;
}
.c9 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c28 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Roboto";
  font-style: normal;
}
.c50 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: "Roboto";
  font-style: normal;
}
.c16 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Roboto";
  font-style: normal;
}
.c37 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9pt;
  font-family: "Arial";
  font-style: normal;
}
.c24 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 8pt;
  font-family: "Roboto";
  font-style: normal;
}
.c12 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Calibri";
  font-style: normal;
}
.c44 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: "Arial";
  font-style: normal;
}
.c31 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: "Arial";
  font-style: normal;
}
.c46 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c43 {
  padding-top: 9pt;
  padding-bottom: 9pt;
  line-height: 1.3500000000000003;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c59 {
  padding-top: 0pt;
  padding-bottom: 21pt;
  line-height: 1.3500000000000003;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c18 {
  padding-top: 9pt;
  padding-bottom: 9pt;
  line-height: 1.3500000000000003;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c40 {
  padding-top: 21pt;
  padding-bottom: 0pt;
  line-height: 1.3500000000000003;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c47 {
  padding-top: 18pt;
  padding-bottom: 4pt;
  line-height: 1.3500000000000003;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c25 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: right;
}
.c4 {
  -webkit-text-decoration-skip: none;
  font-weight: 400;
  text-decoration: none;
  text-decoration-skip-ink: none;
  font-size: 10pt;
  font-family: "Roboto";
}
.c48 {
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c49 {
  padding-top: 14pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c21 {
  padding-top: 9pt;
  padding-bottom: 9pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c34 {
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c32 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.25;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c22 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3500000000000003;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c26 {
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c58 {
  color: #4472c4;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-style: normal;
}
.c27 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c51 {
  padding-top: 0pt;
  padding-bottom: 9pt;
  line-height: 1.25;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c55 {
  padding-top: 18pt;
  padding-bottom: 4pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c38 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c52 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c23 {
  border-left-style: solid;
  padding-left: 30pt;
  border-left-color: #000000;
  border-left-width: 0pt;
}
.c41 {
  background-color: #ffff00;
  font-size: 20pt;
  font-weight: 700;
}
.c35 {
  font-size: 10.5pt;
  font-family: "Arial";
  font-weight: 400;
}
.c60 {
  font-size: 16pt;
  font-family: "Roboto";
  font-weight: 700;
}
.c30 {
  font-size: 15pt;
  font-family: "Roboto";
  font-weight: 400;
}
.c53 {
  font-size: 11pt;
  font-family: "Roboto";
  font-weight: 700;
}
.c56 {
  font-size: 13pt;
  font-family: "Arial";
  font-weight: 700;
}
.c7 {
  font-size: 10pt;
  font-family: "Roboto";
  font-weight: 400;
}
.c61 {
  font-size: 10pt;
  font-family: "Roboto";
  font-weight: 700;
}
.c54 {
  font-weight: 400;
  font-size: 12pt;
  font-family: "Roboto";
}
.c36 {
  font-size: 9pt;
  font-family: "Roboto";
  font-weight: 400;
}
.c62 {
  color: #000000;
  vertical-align: baseline;
  font-style: normal;
}
.c45 {
  font-size: 13pt;
  font-family: "Roboto";
  font-weight: 700;
}
.c20 {
  color: inherit;
  text-decoration: none;
}
.c29 {
  padding: 0;
  margin: 0;
}
.c42 {
  padding: 52pt 52pt 52pt 52pt;
}
.c15 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.c39 {
  background-color: #00ff00;
}
.c14 {
  background-color: #ffffff;
}
.c33 {
  margin-left: 36pt;
}
.c17 {
  height: 12pt;
}
.c19 {
  background-color: #fcfcfc;
}
.c57 {
  font-family: "Calibri";
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Calibri";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 12pt;
  font-family: "Calibri";
}
p {
  margin: 0;
  color: #000000;
  font-size: 12pt;
  font-family: "Calibri";
}
</style>
